import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getTrackDetailsBookingList } from "../../redux/actions/BookingActions";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import { getCurrentDateMysql, getDisplayDateTimeFormat, getCurrentTimeMysql } from "../../util/generalUtills";

const DownloadSlip = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    AuthReducer: { auth,loading,errors },
    bookingReducer:{
      trackDetailsBookingList,
      changedBookingStatus,
      loadingBooking,
      errorsBooking,
    },
  } = useSelector((state) => state);


  useEffect(() => {
    if (auth?.token && id) {
      dispatch(getTrackDetailsBookingList(auth.token, id));
    }
  }, [auth?.token, id]);


  useEffect(() => {
    console.log('trackDetailsBookingList',trackDetailsBookingList)
  },[trackDetailsBookingList]);


  useEffect(() => {
    if(errors)
    {
      if(errors?.status === 401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        window.location.reload();
      }
    }
  },[errors])


  const styles = {
    body: {
      fontFamily: "Arial, sans-serif",
      margin: "0",
      padding: "0",
      backgroundColor: "#f9f9f9",
    },
    documentContainer: {
      width: "80%",
      margin: "10px auto",
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      padding: "10px",
    },
    header: {
      textAlign: "center",
      marginBottom: "10px",
    },
    headerTitle: {
      fontSize: "24px",
      margin: "0",
    },
    headerTitle2: {
      fontSize: "16px",
      margin: "0",
    },
    headerTitle3: {
      fontSize: "16px",
      margin: "0",
    },
    documentContent: {
      fontSize: "16px",
      lineHeight: "15px",
    },
    field: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      borderBottom: "1px dashed #ccc",
      paddingBottom: "5px",
    },
    label: {
      fontWeight: "bold",
      marginRight: "10px",
    },
    value: {
      flex: "1",
      textAlign: "right",
      color: "#555",
    },
    footer: {
      textAlign: "center",
      marginTop: "20px",
      fontSize: "14px",
      color: "#777",
    },
    button: {
      display: "block",
      margin: "20px auto",
      padding: "10px 20px",
      fontSize: "16px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };

  const downloadPDF = () => {
    const element = document.getElementById("document-container");
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("Water_Supply_Document.pdf");
    });
  };

  return (
    <div style={styles.body}>
      <div id="document-container" style={styles.documentContainer}>
        <div style={styles.header}>
          <h1 style={styles.headerTitle}>चालक के लिये जल भरने, खाली कराने स्वीकृति पत्र ‘क’</h1>
          <h1 style={styles.headerTitle2}>प्रपत्र ‘क’</h1>
          <h1 style={styles.headerTitle3}>( यह महक़ी / PHED खंड पर निरीक्षण कर्ताओं द्वारा भरा जाएगा )</h1>
        </div>

        <div style={{ display: 'flex',width:'100%'}}>
          <span style={{width:'75%'}}>बुक नं. </span>
          <span style={{width:'25%'}}>नं. </span>
        </div>

        <br />

        <div style={styles.documentContent}>
          <div style={styles.field}>
            <label style={{fontWeight: 'bold',marginRight: '10px',width:'15%'}}>तहसील / खंड कार्यालय : </label>
            <span style={{textAlign: 'left',width:'45%'}}>{trackDetailsBookingList?.bookingData?.name}</span>
            <label style={{flex: '1 1 0%',textAlign: 'end',fontWeight: 'bold',marginRight: '10px',width:'5%'}}>दिनांक : </label>
            <span style={{textAlign: 'start',width:'10%'}}>{getCurrentDateMysql(trackDetailsBookingList?.bookingData?.booking_date)}</span>
            <label style={{flex: '1 1 0%',textAlign: 'end',fontWeight: 'bold',marginRight: '10px',width:'10%'}}>स्लिप नंबर : </label>
            <span style={{textAlign: 'left',width:'5%'}}> {trackDetailsBookingList?.bookingData?.id}</span>
          </div>

          <div style={styles.field}>
            <label style={{fontWeight: 'bold',width:'30%'}}>तहसील / खंड कार्यालय पर प्रस्थान रजिस्टर क्रमांक : </label>
            <span style={{flex: '1 1 0%',textAlign: 'justify',}}></span>
          </div>

          <div style={styles.field}>
            <label style={{fontWeight: 'bold',width:'10%'}}>प्रारम्भिक स्थान : </label>
            <span style={{textAlign: 'left',width:'40%'}}>{trackDetailsBookingList?.bookingData?.hydrantName}</span>
            <label style={{fontWeight: 'bold',width:'15%'}}>जल स्रोत का नाम : </label>
            <span style={{textAlign: 'left',width:'35%'}}>{trackDetailsBookingList?.bookingData?.hydrantAddress}</span>
          </div>

          <div style={styles.field}>
            <label style={{fontWeight: 'bold',width:'10%'}}>टंकी क्षमता : </label>
            <span style={{textAlign: 'left',width:'40%'}}></span>
            <label style={{textAlign: 'left',width:'50%'}}>लीटर ।</label>
          </div>

          <div style={styles.field}>
            <label style={{fontWeight: 'bold',width:'12%'}}>टैंकर / ट्रक का नंबर : </label>
            <span style={{textAlign: 'left',width:'88%'}}>{trackDetailsBookingList?.bookingData?.vehicle_number}</span>
          </div>

          <div style={styles.field}>
            <label style={{ fontWeight: 'bold',width:'10%' }}>ड्राइवर का नाम : </label>
            <span style={{ textAlign: 'left',width:'40%' }}>{trackDetailsBookingList?.bookingData?.driver_name} ({trackDetailsBookingList?.bookingData?.driver_mobile})</span>
            <label style={{ fontWeight: 'bold',width:'10%' }}>खलासी का नाम : </label>
            <span style={{ textAlign: 'left',width:'40%' }}></span>
          </div>

          <div style={styles.field}>
            <label style={{fontWeight: 'bold',width:'20%'}}>साथ चलने वाले टैंकर या ट्रक नंबर : </label>
            <span style={{textAlign: 'left',width:'80%'}}></span>
          </div>

          <div style={styles.field}>
            <label style={{fontWeight: 'bold',width:'50%'}}>पानी सप्लाई प्राप्ति चलने वाले करने वाले ट्रक / टैंकर के ड्राइवरों के नाम - </label>
          </div>

          <div style={styles.field}>
            <label style={{fontWeight: 'bold',width:'3%'}}>(1) : </label>
            <span style={{textAlign: 'left',width:'47%'}}>fdfd</span>
            <label style={{fontWeight: 'bold',width:'3%'}}>(2) : </label>
            <span style={{textAlign: 'left',width:'47%'}}></span>
          </div>

          <div style={styles.field}>
            <label style={{fontWeight: 'bold',width:'11%'}}>टैंकर / टंकी लक्ष्य : </label>
            <span style={{textAlign: 'left',width:'39%'}}>{trackDetailsBookingList?.bookingData?.destinationIdBookingDetailsDestination?.name} </span>
            <label style={{fontWeight: 'bold',width:'5%'}}>(गांव) : </label>
            <span style={{textAlign: 'left',width:'41%'}}>{trackDetailsBookingList?.bookingData?.destinationIdBookingDetailsDestination?.address}</span>
            <label style={{fontWeight: 'bold',width:'4%'}}>(स्थान)</label>
          </div>

          <div style={{display: 'flex',justifyContent: 'space-between',marginBottom: '6px',marginTop: '8%'}}>
            <label style={{fontWeight: 'bold',textAlign: 'right', width:'100%'}}>( तहसील / PHED कर्मचारी के हस्ताक्षर )</label>
          </div>

          <div style={{display: 'flex',justifyContent: 'space-between',marginBottom: '-4px'}}>
            <label style={{fontWeight: 'bold',textAlign: 'right', width:'100%', marginRight: '8%'}}>मय दिनांक</label>
          </div>
        </div>

        <div style={{textAlign: 'center',marginBottom: '20px',marginTop: '5%'}}>
          <h1 style={styles.headerTitle}>( यह जल विभाग के कर्मचारी द्वारा भरा जाएगा )</h1><br />
        </div>

        <div style={styles.documentContent}>
          <div style={styles.field}>
            <label style={{fontWeight: 'bold',width:'12%'}}>टैंकर / ट्रक का नंबर :</label>
            <span style={{textAlign: 'left',width:'88%'}}>{trackDetailsBookingList?.bookingData?.vehicle_number}</span>
          </div>

          <div style={styles.field}>
            <label style={{fontWeight: 'bold',width:'30%'}}>टैंकर / ट्रक का स्रोत से पानी भराकर चलने का समय - </label>
          </div>

          <div style={styles.field}>
            <label style={{fontWeight: 'bold',width:'8%'}}>( शब्दों में ) : </label>
            <span style={{textAlign: 'left',width:'52%'}}></span>
            <label style={{fontWeight: 'bold',width:'25%'}}>जल स्रोत पर रखे रजिस्टर का क्रमांक : </label>
            <span style={{textAlign: 'left',width:'15%'}}></span>
          </div>

          <div style={{display: 'flex',justifyContent: 'space-between',marginBottom: '6px',marginTop: '8%'}}>
            <label style={{fontWeight: 'bold',textAlign: 'right', width:'100%'}}>( जल विभाग कर्मचारी के हस्ताक्षर )</label>
          </div>

          <div style={{display: 'flex',justifyContent: 'space-between',marginBottom: '10px',borderBottom: '1px dashed rgb(204, 204, 204)',paddingBottom: '24px'}}>
            <label style={{fontWeight: 'bold',textAlign: 'right', width:'100%', marginRight: '7%'}}>मय दिनांक</label>
          </div>

          <div style={{display: 'flex',justifyContent: 'space-between',marginBottom: '10px',paddingBottom: '24px'}}>
            <span style={{textAlign: 'left',width:'60%'}}></span>
            <label style={{fontWeight: 'bold',textAlign: 'right', width:'40%'}}>का लक्ष्य पर टैंकर का सारा पानी नियत स्थान पर खाली करा दिया गया</label>
          </div>

          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px', borderBottom: '1px dashed rgb(204, 204, 204)', paddingBottom: '3%', marginTop: '6%'}}>
            <div style={{display: 'flex', flexDirection: 'column',}}>
              <label style={{fontWeight: 'bold',textAlign: 'left', width:'50%', fontSize: '16px'}}>हस्ताक्षर</label>
              <label style={{fontWeight: 'bold',textAlign: 'left', width:'50%', fontSize: '12px'}}>( ड्राइवर )</label>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', }}>
              <label style={{fontWeight: 'bold',textAlign: 'center', width:'50%', fontSize: '16px'}}>हस्ताक्षर</label>
              <label style={{fontWeight: 'bold',textAlign: 'center', width:'50%', fontSize: '12px'}}>( खलासी )</label>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', }}>
              <label style={{fontWeight: 'bold',textAlign: 'center', width:'50%', fontSize: '16px'}}>हस्ताक्षर</label>
              <label style={{fontWeight: 'bold',textAlign: 'center', width:'50%', fontSize: '12px'}}>( सरपंच / उप-सरपंच / वार्ड पंच / पटवारी )</label>
            </div>

            <div style={{display: 'flex', flexDirection: 'column',}}>
              <label style={{fontWeight: 'bold',textAlign: 'center', width:'20%', fontSize: '16px'}}>हस्ताक्षर</label>
              <label style={{fontWeight: 'bold',textAlign: 'right', width:'80%', fontSize: '12px'}}>( ग्राम सेवक / अध्यापक / महिला )</label>
            </div>
          </div>

          <div style={styles.field}>
            <label style={{fontWeight: 'bold',marginRight: '10px'}}>तहसील / खंड कार्यालय पर वापसी रिपोर्ट करने का समय : </label>
            <span style={{textAlign: 'right'}}>Khudani</span>
            <label style={{flex: '1 1 0%',textAlign: 'end',fontWeight: 'bold',marginRight: '10px'}}>दिनांक : </label>
            <span style={{textAlign: 'start'}}>Khudani</span>
          </div>

          <div style={styles.field}>
            <label style={styles.label}>तहसील / खंड कार्यालय पर आगमन रजिस्टर क्रमांक : </label>
            <span style={{flex: '1 1 0%',textAlign: 'justify',}}>14</span>
          </div>

          <div style={{display: 'flex',justifyContent: 'space-between',marginBottom: '6px',marginTop: '8%'}}>
            <label style={{flex: '1 1 0%',textAlign: 'end',fontWeight: 'bold',marginRight: '10px'}}>( तहसील / खंड कार्यालय कर्मचारी के हस्ताक्षर )</label>
          </div>

          <div style={{display: 'flex',justifyContent: 'space-between',marginBottom: '10px',borderBottom: '1px dashed rgb(204, 204, 204)',paddingBottom: '24px'}}>
            <label style={{flex: '1 1 0%',textAlign: 'end',fontWeight: 'bold',marginRight: '9%'}}>मय दिनांक</label>
          </div>
        </div>

        <div style={styles.footer}>
          <p>Generated for display purposes only. Water Department, 2024.</p>
        </div>

      </div>

      <button style={styles.button} onClick={downloadPDF}>
        Download PDF
      </button>
    </div>
  );
};

export default DownloadSlip;
