import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { deleteVehicleData, getVehicleList } from "../../redux/actions/VehicleActions";
import RoundedPagination from "../../Components/Paginate";
import FormInput from "../../Components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import {getDisplayDateTimeFormat} from '../../util/generalUtills'
import { getUserList } from "../../redux/actions/UserActions";
import Preloader from "../../Components/Preloader";
import classNames from "classnames";



const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    userReducer: {
      userLists,
      loadingUsers,
      errorsUsers,
      userCount,
      changeUserStatus,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  
  // useEffect(() => {
  //   dispatch(getVehicleList(auth?.token, paginate, page, search, sort));
  //   console.log('userLists',userLists);
  // }, [auth, paginate, page, search, sort]);

  const fetchVehicleList = useCallback(() => {
    if (auth?.token) {
      dispatch(getUserList(auth.token, paginate, page, search, sort));
    }
  }, [auth?.token, paginate, page, search, sort, dispatch]);

  useDebounce(fetchVehicleList, 500, [auth, paginate, page, search, sort]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
    
  },[errors])


  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? userCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1); // Reset to the first page whenever the paginate value changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleDeleteRequest = (id) => {
    swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, delete it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteVehicleData(auth?.token,id))
      }
    });
  };


  useEffect(() => {
    //console.log(changeUserStatus);
    if(changeUserStatus?.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changeUserStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_STATUS' });
      dispatch(getVehicleList(auth?.token, paginate, page, search, sort));
    }
    
  },[changeUserStatus])



  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("User"), path: "/Users", active: true },
        ]}
        title={t("User")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
            <div className="mb-2">
              <Row>
                <Col lg={3}>
                  <div className="d-flex align-items-center">
                  <input
                    type="search"
                    placeholder="Search..."
                    className="form-control ms-1"
                    autoComplete="off"
                    value={search}
                    onChange={handleSearchChange}
                  />
                  </div>
                </Col>
                <Col lg={9}>
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="button-list">
                      <Link to="/Add-User">
                        <Button variant="success">
                          <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                        </Button>
                      </Link>
                      <Button variant="dark">
                        <i className="mdi mdi-printer me-1"></i>Print
                      </Button>
                      <Button variant="info">
                        <i className="mdi mdi-file-excel me-1"></i>Excel
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div style={{overflowY:'auto',maxHeight:'500px',border: "1px solid #dee2e6"}}>
              <Table className="mb-0 table-bordered"
                size="sm"
                striped
                style={{ width: "100%", tableLayout: "fixed" }}>
                  <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
                  <tr>
                    <th>SR No.</th>
                    <th>Name</th>
                    <th>Contact</th>
                    <th>User Type</th>
                    <th>City</th>
                    <th>Username</th>
                    <th>Reporting Authority</th>
                    <th>Registered On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {userLists.length === 0 ? (
                  <tr>
                    <td colSpan="10">No data found</td>
                  </tr>
                ) : (<>
                  {userLists?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                        
                        <td>{item?.name}</td>
                        <td>{item?.contact}</td>
                        <td>{item?.userTypeName}</td>
                        <td>{item?.city_name}</td>
                        <td>{item?.username}</td>
                        <td>{item?.reportingUserName}</td>
                        <td>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                        <td>
                          
                          <Link to={`/Update-User/${item?.id}`} className="action-icon text-info"> 
                            <i className="mdi mdi-square-edit-outline"></i>
                          </Link>
                          <Link to="#" className="action-icon text-danger" onClick={() => handleDeleteRequest(item?.id)} >
                            {" "}
                            <i className="mdi mdi-delete"></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}</>
                )}
                </tbody>
              </Table>
            </div>
            <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
              
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(userCount / paginate)}</strong> </span>
                <RoundedPagination
                      currentPage={page}
                      totalPages={Math.ceil(userCount / paginate)}
                      onPageChange={(pageNumber) => setPage(pageNumber)}
                    />
              
              
            </div>
            
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingUsers && (<Preloader/>)}
    </>
  );
};

export default Dashboard;
