import React, { useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import { Row, Col, Card, Button, InputGroup, Form, Spinner } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { saveVehicleData } from "../../redux/actions/VehicleActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import HyperDatepicker from "../../Components/Datepicker";
import { getAllUsertypeUserList, getHydrantList, getMasterBlockBookingList, getMasterDestinationList, getMasterHydrantList, getMasterSarpanchBookingList, getMasterSettings } from "../../redux/actions/MasterActions";
import { getHydrantListById } from "../../redux/actions/HydrantActions";
import { getDateTimeMysqlGivenFormatYmdhi } from "../../util/generalUtills";
import { getDestinationList, getDestinationListById } from "../../redux/actions/DestinationActions";
import { generateSlipData, getSlipAllotmentListById, saveBookingData } from "../../redux/actions/BookingActions";
import LeafletMap from "../../Components/LeafletMap";
import Preloader from "../../Components/Preloader";


const GenerateSlip = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    tanker_number: 1,
    no_of_tanker: 1,
    hydrantLat: 0.00,
    hydrantLng: 0.00,
    destinationLat: 0.00,
    destinationLng: 0.00,
    booking_date:new Date(),
    totalDistance: 0
  })
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth,loading,errors },
    masterReducer:{
      loadingMaster,
      errorsMaster,
      masterSettingsLists,
      masterHydrantLists,
      masterDestinationLists,
      masterSarpanchBookingLists,
      masterBlockBookingLists,
      masterUsertypeUserLists,
    },
    hydrantReducer:{
      hydrantLists,
      loadingHydrant
    },
    destinationReducer:{
      destinationLists,
      loadingDestination,
    },
    bookingReducer:{
      slipAllotmentList,
      changedBookingStatus,
      loadingBooking,
      errorsBooking,
    },
    userReducer: {
      userLists,
      loadingUser,
      errorsUser,
      changedUserStatus,
    },
  } = useSelector((state) => state);
  
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getSlipAllotmentListById(auth.token,id));
      dispatch(getMasterSettings(auth.token));
      //dispatch(getAllUsertypeUserList(auth.token, 13,''));
    }
    
  }, [auth?.token]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    //console.log(formData)
    swal.fire({
      title: "Confirmation ?",
      text: `Total distance you have entered is ${formData.totalDistance}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Confirm my booking!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(generateSlipData(auth?.token,formData));
      }
    });
    
  };

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        window.location.reload();
      }
    }
    
  },[errors])

  useEffect(() => {
    //console.log('errorsBooking',errorsBooking);
    if(errorsBooking?.status==409){
      swal.fire({
        show: true,
        title: "Oops!",
        text: errorsBooking?.data?.message,
        icon: "error",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_STATUS' });
    }
    else if(errorsBooking?.status==400){
      //dispatch({ type: 'RESET_DELETE_STATUS' });
    }
  },[errorsBooking])

  const inputHandler = (e) => {
    const { value, name } = e.target
    setFormData({ ...formData, [name]: value });
    
  }

  const inputHandlerNumeric = (e) => {
    const { value, name } = e.target;
    // Replace all non-numeric characters except the first dot
    const processedValue = value
      .replace(/[^\d.]/g, '')          // Remove all non-numeric and non-dot characters
      .replace(/^(\d*\.?)|(\d*)\./g, '$1$2'); // Remove any additional dots
  
    setFormData({ ...formData, [name]: processedValue });
  };
  

  // const inputHandlerMobile = (e) => {
  //   const { value, name } = e.target;
  //   let processedValue = value.replace(/\D/g, '');
  //   if (processedValue.length > 10) {
  //     processedValue = processedValue.slice(0, 10);
  //   }
  //   const isValidMobileNumber = /^([56789]\d{9})$/.test(processedValue);
  //   if (isValidMobileNumber) {
  //     setFormData({ ...formData, [name]: processedValue });
  //   }
  // };

  const inputHandlerMobile = (e) => {
    const { value, name } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  
  

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
    
  };

  useEffect(() => {
    //console.log('slipAllotmentList',slipAllotmentList)
    setFormData((prevFormData) => ({
      ...prevFormData,
      slipGenerationId: slipAllotmentList?.id ?? ' ',
      hydrantName: slipAllotmentList?.hydrantName ?? ' ',
      hydrantLat: slipAllotmentList?.hydrantLat ?? '0.00',
      hydrantLng: slipAllotmentList?.hydrantLng ?? '0.00',
      vehicleType: 'Tanker',
    }));
    dispatch(getMasterHydrantList(auth.token,slipAllotmentList?.jen_id));
    dispatch(getMasterDestinationList(auth.token,slipAllotmentList?.jen_id));
    dispatch(getMasterBlockBookingList(auth.token,slipAllotmentList?.jen_id));
    dispatch(getMasterSarpanchBookingList(auth.token,slipAllotmentList?.jen_id));
  },[slipAllotmentList])

  const selectHandler = (selectedData,name) => {
    //console.log(selectedData,name);
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));

    if(name==="jen_id")
    {
      
    }
  };

  useEffect(() => {
    if(changedBookingStatus.success)
    {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedBookingStatus.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_STATUS' });
      navigate(-1);
    }
  },[changedBookingStatus])

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errorsBooking?.data?.vehicle_model ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errorsBooking?.data?.vehicle_model ? 'red' : base.borderColor,
      }
    }),
  };

  const onDateChange = (name,value) => {
    if (value) {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_BOOKING_STATUS' });
  }, [dispatch]);

  useEffect(() => {
    //generateTankerOptions(masterSettingsLists.no_of_tanker)
    //console.log('masterSettingsLists',masterSettingsLists);
  },[masterSettingsLists])

  const generateTankerOptions = (noOfTankers) => {
    const options = [];
    for (let i = 1; i <= noOfTankers; i++) {
      options.push({ value: `${i}`, label: `${i} Tanker` });
    }
    return options;
  };

  useEffect(() => {
    dispatch(getHydrantListById(auth?.token,formData?.hydrantId))
  },[formData?.hydrantId])

  useEffect(() => {
    //console.log('formData?.hydrantId',hydrantLists);
    setFormData((prevFormData) => ({
      ...prevFormData,
      hydrantName: hydrantLists?.name ?? ' ',
      hydrantLat: hydrantLists?.lat ?? '0.00',
      hydrantLng: hydrantLists?.lng ?? '0.00',
      vehicleType: 'Tanker',
    }));
    
  },[hydrantLists])

  useEffect(() => {
    dispatch(getDestinationListById(auth?.token,formData?.destinationId))
  },[formData?.destinationId])

  useEffect(() => {
    //console.log('formData?.destinationId',hydrantLists);
    setFormData((prevFormData) => ({
      ...prevFormData,
      destinationName: destinationLists?.name ?? ' ',
      destinationLat: destinationLists?.lat ?? '0.00',
      destinationLng: destinationLists?.lng ?? '0.00'
    }));
  },[destinationLists])


  useEffect(() => {
    //console.log('formData?',formData);
  },[formData])
  //

  /*
   * handle form submission
   */
  

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Generate Slip"), path: "Generate-Slip" },
          { label: t("Generate Slip"), path: "/Generate-Slip", active: true },
        ]}
        title={t("Generate Slip")}
      />
      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <div style={{ position: "relative", overflow: "hidden", height: "450px" }}>
              <LeafletMap
                mapHeight={"450px"}
                center={[formData.hydrantLat, formData.hydrantLng]}
                zoom={15}
                hydrantMarkerPosition={[formData.hydrantLat, formData.hydrantLng]}
                destinationMarkerPosition={[formData.destinationLat, formData.destinationLng]}
                setFormData={setFormData}
              />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <Card.Body>
              {errorsBooking?.data?.message && (
                <div className="alert alert-danger">
                  {errorsBooking.data.message}
                </div>
              )}
              <Row>
                <Col lg={6}>
                  <FormInput
                    label={t("Customer Mobile")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.mobile}
                    name="mobile"
                    onChange={inputHandlerMobile}
                    containerClass={"mb-3"}
                  />
                </Col>
                <Col lg={6}>
                  <FormInput
                    label={t("Customer Name")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.name}
                    name="name"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>
                <Col lg={6}>
                  <FormInput
                    label={t("Alt Number")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.altNumber}
                    name="altNumber"
                    onChange={inputHandlerMobile}
                    containerClass={"mb-3"}
                  />
                </Col>
                
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Booking Type")}</Form.Label>
                    <Select
                      classNamePrefix="react-select"
                      name="booking_type"
                      defaultValue={formData.booking_type}
                      options={[
                        { value: "Emergency", label: "Emergency" },
                      ]}
                      onChange={(selectedData) => selectHandler(selectedData[0].value,"booking_type")}
                      styles={customSelectStyles}
                    />
                    {errorsBooking?.data?.booking_type && (
                      <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsBooking?.data.booking_type}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <FormInput
                    label={t("Hydrant")}
                    type="text"
                    disabled={true}
                    errors={errorsBooking?.data}
                    value={formData.hydrantName}
                    name="altNumber"
                    onChange={inputHandlerMobile}
                    containerClass={"mb-3"}
                  />
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Destination")}</Form.Label>
                    <Select
                      classNamePrefix="react-select"
                      name="destinationId"
                      defaultValue={formData.destinationId}
                      options={masterDestinationLists}
                      valueField={"id"}
                      labelField={"nameAddress"}
                      onChange={(selectedData) => selectHandler(selectedData[0].id,"destinationId")}
                      styles={customSelectStyles}
                    />
                    {errorsBooking?.data?.destinationId && (
                      <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsBooking?.data.destinationId}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <FormInput
                    label={t("Hydrant Lat")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.hydrantLat}
                    name="hydrantLat"
                    containerClass={"mb-3"}
                  />
                </Col>
                <Col lg={3}>
                  <FormInput
                    label={t("Hydrant Lng")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.hydrantLng}
                    name="hydrantLng"
                    containerClass={"mb-3"}
                  />
                </Col>
                <Col lg={3}>
                  <FormInput
                    label={t("Destination Lat")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.destinationLat}
                    name="destinationLat"
                    
                    containerClass={"mb-3"}
                  />
                </Col>
                <Col lg={3}>
                  <FormInput
                    label={t("Destination Lng")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.destinationLng}
                    name="destinationLng"
                    
                    containerClass={"mb-3"}
                  />
                </Col>
                <Col lg={4}>
                  <FormInput
                    label={t("Distance")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.totalDistance}
                    name="totalDistance"
                    onChange={inputHandlerNumeric}
                    containerClass={"mb-3"}
                  />
                </Col>
                
                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Approval One")}</Form.Label>
                    <Select
                      classNamePrefix="react-select"
                      name="approval_one"
                      defaultValue={formData?.approval_one}
                      options={masterSarpanchBookingLists}
                      valueField={"id"}
                      labelField={"name"}
                      onChange={(selectedData) => selectHandler(selectedData[0].id,"approval_one")}
                      styles={customSelectStyles}
                    />
                    {errorsBooking?.data?.approval_one && (
                      <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsBooking?.data.approval_one}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Approval Two")}</Form.Label>
                    <Select
                      classNamePrefix="react-select"
                      name="tahsildaar"
                      defaultValue={formData?.tahsildaar}
                      options={masterBlockBookingLists}
                      valueField={"id"}
                      labelField={"name"}
                      onChange={(selectedData) => selectHandler(selectedData[0].id,"tahsildaar")}
                      styles={customSelectStyles}
                    />
                    {errorsBooking?.data?.tahsildaar && (
                      <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsBooking?.data.tahsildaar}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              
              {loading ? 
              <>
                <Button color="primary" disabled>
                  <Spinner
                    className="spinner-border-sm me-1"
                    tag="span"
                    color="white"
                  />
                  Please wait ...
                </Button>
              </>
              :
              <>
              <Button type="button" onClick={handleSubmit} disabled={loading}>
                Submit
              </Button>
              </>
              }
              
              
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingBooking && (<Preloader/>)}
      {loadingDestination && (<Preloader/>)}
      {loadingMaster && (<Preloader/>)}
      {loadingUser && (<Preloader/>)}
      {loadingHydrant && (<Preloader/>)}
      
    </>
  );
};

export default GenerateSlip;