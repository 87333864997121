import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Polyline, Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/images/marker-shadow.png';
// Import the rotated marker plugin
import 'leaflet-rotatedmarker';
import { FaPlay, FaPause } from 'react-icons/fa';

// Custom vehicle icon
const vehicleIcon = L.icon({
  iconUrl: 'https://phedrserver.asginnovations.in/lorry.png',
  iconSize: [35, 35],
  iconAnchor: [17, 17], // Set the anchor point to the center of the icon for proper rotation
  popupAnchor: [0, -20],
});

// Helper component to fit map bounds based on route points only once (on load)
const FitMapBoundsOnLoad = ({ routePoints }) => {
  const map = useMap();
  const boundsFitRef = useRef(false); // Ref to ensure bounds are only fit once

  useEffect(() => {
    if (routePoints.length > 0 && !boundsFitRef.current) {
      const bounds = L.latLngBounds(routePoints);
      map.fitBounds(bounds); // Fit the map to show all route points, only once on load
      boundsFitRef.current = true; // Set ref to true after fitting bounds
    }
  }, [routePoints, map]);

  return null;
};

// Linear interpolation function between two points
const interpolatePosition = (start, end, progress) => {
  const lat = start[0] + (end[0] - start[0]) * progress;
  const lng = start[1] + (end[1] - start[1]) * progress;
  return [lat, lng];
};

const LeafletMapHistoryVehicle = ({ mapHeight, routeData ,isMarkerShow , handleNewSearch}) => {
  const [play, setPlay] = useState(true);
  const [progress, setProgress] = useState(0); // Represents current position on the route
  const [interpolatedPosition, setInterpolatedPosition] = useState([0, 0]); // For smooth position updates
  const [step, setStep] = useState(0); // Save the current step for interpolation
  const [speed, setSpeed] = useState(1); // Speed multiplier (1x, 2x, 4x, 8x)
  const intervalRef = useRef(null); // To store the setInterval reference

  const totalPoints = routeData?.length;

  // Function to update the interval based on speed
  const startInterval = (speedMultiplier) => {
    clearInterval(intervalRef.current);
    const intervalTime = 500 / speedMultiplier; // Adjust the time based on speed
    intervalRef.current = setInterval(() => {
      setStep((prevStep) => {
        const newStep = prevStep + 0.01; // Smooth transition between two points
        if (newStep >= 1) {
          setProgress((prevProgress) => {
            const nextProgress = Math.min(prevProgress + 1, totalPoints - 1);
            return nextProgress;
          });
          return 0; // Reset step when moving to the next point
        } else {
          // Update interpolated position for smoother movement
          const routePoints = routeData?.map((location) => [
            parseFloat(location.lat),
            parseFloat(location.lng),
          ]);
          const newInterpolatedPosition = interpolatePosition(
            routePoints[progress],
            routePoints[progress + 1],
            newStep
          );
          setInterpolatedPosition(newInterpolatedPosition);
          return newStep;
        }
      });
    }, intervalTime); // Update based on speed
  };

  useEffect(() => {
    const routePoints = routeData?.map((location) => [
      parseFloat(location.lat),
      parseFloat(location.lng),
    ]);

    // When playing, start the interval with the correct speed
    if (play && progress < totalPoints - 1) {
      startInterval(speed);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [play, progress, speed, totalPoints, routeData]);

  const handlePlayPause = () => {
    setPlay(!play);
  };

  const handleSliderChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setProgress(newValue);
    setStep(0); // Reset step when manually adjusting the slider

    // Update position immediately when slider is moved
    const newInterpolatedPosition = [
      parseFloat(routeData[newValue].lat),
      parseFloat(routeData[newValue].lng),
    ];
    setInterpolatedPosition(newInterpolatedPosition);

    setPlay(false); // Stop playing when the user adjusts the slider
  };

  // Function to handle speed change
  const handleSpeedChange = (newSpeed) => {
    setSpeed(newSpeed);
    if (play) {
      startInterval(newSpeed); // Update the interval immediately if playing
    }
  };

  // Format timestamp for display
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString(); // You can adjust this for different formats
  };

  return (
    <div style={{ height: mapHeight }}>
      {/* Controls Section */}

      {isMarkerShow && (<>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            borderRadius: '8px',
            marginBottom: '10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          {play ? 
            <Button type="button" onClick={handlePlayPause} >
              Stop
            </Button> : 
            <Button type="button" onClick={handlePlayPause} >
              Start
            </Button>
          }
            
          <Button type="button" onClick={handleNewSearch} >
            New Search
          </Button>

          <div style={{ textAlign: 'center' }}>
            <div>
              <Button onClick={() => handleSpeedChange(1)}>1X</Button>
              <Button onClick={() => handleSpeedChange(2)}>2X</Button>
              <Button onClick={() => handleSpeedChange(4)}>4X</Button>
              <Button onClick={() => handleSpeedChange(8)}>8X</Button>
              <Button onClick={() => handleSpeedChange(16)}>16X</Button>
            </div>
          </div>

          {/* Speedometer and Time */}
          <div style={{ textAlign: 'center' }}>
            <h5 style={{ marginTop: '10px' }}>Speed {routeData[progress]?.speed || '0'} km/h Time {formatTimestamp(routeData[progress]?.timestamp)}</h5>
          </div>

        </div>
        <div style={{ marginBottom: '10px' }}>
          <input
            type="range"
            min="0"
            max={totalPoints - 1}
            value={progress}
            onChange={handleSliderChange}
            style={{ width: '100%' }}
          />
        </div>
      </>)}
      

      

      {/* Map Section */}
      <MapContainer center={[0, 0]} zoom={13} style={{ height: '85%' }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {/* Fit the map bounds based on route points */}
        <FitMapBoundsOnLoad
          routePoints={routeData?.map((location) => [
            parseFloat(location.lat),
            parseFloat(location.lng),
          ])}
        />

        {/* Render the route using Polyline */}
        {routeData?.length > 0 && (
          <Polyline
            positions={routeData?.map((location) => [
              parseFloat(location.lat),
              parseFloat(location.lng),
            ])}
            color="blue"
          />
        )}
        {isMarkerShow && (<> 
          {routeData?.length > 0 && (
            <Marker
              position={
                interpolatedPosition || [
                  parseFloat(routeData[progress].lat),
                  parseFloat(routeData[progress].lng),
                ]
              }
              icon={vehicleIcon}
            />
          )}
        </>)}
        
      </MapContainer>
    </div>
  );
};

export default LeafletMapHistoryVehicle;
