import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { deleteFareData, getFareList } from "../../redux/actions/FareMasterActions";
import RoundedPagination from "../../Components/Paginate";
import FormInput from "../../Components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../util/generalUtills";
import Preloader from "../../Components/Preloader";



const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    fareMasterReducer: {
      loadingFare,
      fareLists,
      errorsFare,
      fareCount,
      changedFareStatus,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;


  const fetchFareList = useCallback(() => {
    if (auth?.token) {
      dispatch(getFareList(auth.token, paginate, page, search, sort));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchFareList, 500, [auth, paginate, page, search, sort]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status === 401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])



  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? fareCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleDeleteRequest = (id) => {
    swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, delete it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFareData(auth?.token,id))
      }
    });
  };


  useEffect(() => {
    //console.log(changedFareStatus);
    if(changedFareStatus.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changedFareStatus.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_FARE_STATUS' });
      dispatch(getFareList(auth?.token, paginate, page, search, sort));
    }
    
  },[changedFareStatus])



  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Fare Master"), path: "/Fare-Master", active: true },
        ]}
        title={t("Fare Master")}
      />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    <div className="d-flex align-items-center">
                    <input
                      type="search"
                      placeholder="Search..."
                      className="form-control ms-1"
                      autoComplete="off"
                      value={search}
                      onChange={handleSearchChange}
                    />
                    </div>
                  </Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="button-list">
                        <Link to="/Add-Fare-Master">
                          <Button variant="success">
                            <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                          </Button>
                        </Link>
                        <Button variant="dark">
                          <i className="mdi mdi-printer me-1"></i>Print
                        </Button>
                        <Button variant="info">
                          <i className="mdi mdi-file-excel me-1"></i>Excel
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div style={{overflowY:'auto',maxHeight:'500px',border: "1px solid #dee2e6"}}>
                <Table className="mb-0 table-bordered"
                  size="sm"
                  striped
                  style={{ width: "100%", tableLayout: "fixed" }}>
                  <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
                    <tr>
                      <th>SR No.</th>
                      <th>Rate Type</th>
                      <th>Day Rate</th>
                      <th>Night Rate</th>
                      <th>Minimum Km</th>
                      <th>Maximum Km</th>
                      <th>Registered At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {fareLists.length === 0 ? (
                    <tr>
                      <td colSpan="7">No data found</td>
                    </tr>
                  ) : (<>
                    {fareLists?.map?.((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>{item?.rate_type}</td>
                          <td>{item?.rate_day}</td>
                          <td>{item?.rate_night}</td>
                          <td>{item?.min_km}</td>
                          <td>{item?.max_km}</td>
                          <td>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                          <td>
                            <Link to={`/Update-Fare-Master/${item?.id}`} className="action-icon text-info"> 
                              <i className="mdi mdi-square-edit-outline"></i>
                            </Link>

                            <Link to="#" className="action-icon text-danger" onClick={() => handleDeleteRequest(item?.id)} >
                              {" "}
                              <i className="mdi mdi-delete"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}</>
                  )}
                  </tbody>
                </Table>
              </div>
              <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(fareCount / paginate)}</strong> </span>
                <RoundedPagination
                  currentPage={page}
                  totalPages={Math.ceil(fareCount / paginate)}
                  onPageChange={(pageNumber) => setPage(pageNumber)}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingFare && (<Preloader/>)}
    </>
  );
};

export default Dashboard;
