const initialUserPermission = {
    userPermissionByUserIdLists: [],
    userPermissionStatus: [],
    changedUserPermissionStatus : [],
    loadingUserPermission: false,
    errorsUserPermission: {}
}

const  userPermissionReducer = (state = initialUserPermission, action) => {
    switch (action.type) {
        case "GET_USER_PERMISSION_LISTBY_USER_ID_PENDING":
        case "UPDATE_USER_PERMISSION__PENDING":
            return { ...state, loadingUserPermission: true };

        case "UPDATE_USER_PERMISSION__FULFILLED":
            return {
                ...state,
                loadingUserPermission: false,
                changedUserPermissionStatus: action.payload,
            };

        
        case "GET_USER_PERMISSION_LISTBY_USER_ID_FULFILLED":
            return {
                ...state,
                loadingUserPermission: false,
                userPermissionByUserIdLists: action.payload
            };

        case "RESET_DELETE_USER_PERMISSION__STATUS":
            return {
                ...state,
                loadingUserPermission: false,
                userPermissionByUserIdLists:[],
                changedUserPermissionStatus: [],
                errorsUserPermission:{}
            };

        case "UPDATE_USER_PERMISSION__REJECTED":
        case "GET_USER_PERMISSION_LISTBY_USER_ID_REJECTED":
            return {
                ...state,
                loadingUserPermission: false,
                errorsUserPermission: action.payload
            };

        default:
            return state;
    }
}

export default userPermissionReducer;