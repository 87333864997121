const initialState = {
    masterSettingsLists: [],
    masterStateLists: [],
    masterHydrantLists:[],
    masterUserLists:[],
    masterDestinationLists:[],
    masterCityStateWiseLists:[],
    masterVehicleLists:[],
    masterDriverLists:[],
    masterUsertypeLists:[],
    masterUsertypeUserLists:[],
    masterBlockBookingLists:[],
    masterSarpanchBookingLists:[],
    reverseAddressList:[],
    loadingMaster: false,
    errorsMaster: {}
}

const  masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MASTER_SETTINGS_PENDING":
        case "GET_MASTER_HYDRANT_PENDING":
        case "GET_MASTER_STATE_PENDING":
        case "GET_MASTER_CITY_STATE_WISE_PENDING":
        case "GET_MASTER_VEHICLE_PENDING":
        case "GET_MASTER_DRIVER_PENDING":
        case "GET_MASTER_VENDOR_PENDING":
        case "GET_MASTER_DESTINATION_PENDING":
        case "GET_MASTER_USERTYPE_PENDING":
        case "GET_MASTER_USERTYPE_USER_WISE_PENDING":
        case "GET_MASTER_USERTYPE_USER_PENDING":
        case "GET_REVERSE_GEOCODING_PENDING":
        case "GET_MASTER_BLOCK_BOOKING_LIST_PENDING":
        case "GET_MASTER_SARPANCH_BOOKING_LIST_PENDING":
        case "GET_MASTER_VEHICLE_JEN_WISE_PENDING":
        case "GET_MASTER_USER_USERTYPE_WISE_PENDING":
            return { ...state, loadingMaster: true };

        case "GET_MASTER_SETTINGS_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterSettingsLists: action.payload,
            };

        case "GET_MASTER_HYDRANT_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterHydrantLists: action.payload,
            };

        case "GET_MASTER_DESTINATION_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterDestinationLists: action.payload,
            };

        case "GET_MASTER_STATE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterStateLists: action.payload
        };

        case "GET_MASTER_CITY_STATE_WISE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterCityStateWiseLists: action.payload
            };

        case "GET_MASTER_VEHICLE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterVehicleLists: action.payload
            };

        case "GET_MASTER_VEHICLE_JEN_WISE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterVehicleLists: action.payload
            };

        case "GET_MASTER_USER_USERTYPE_WISE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterUserLists: action.payload
            };

        case "GET_MASTER_DRIVER_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterDriverLists: action.payload
            };

        case "GET_MASTER_VENDOR_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterVendorLists: action.payload
            };

        case "GET_MASTER_BLOCK_BOOKING_LIST_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterBlockBookingLists: action.payload
            };

        case "GET_MASTER_USERTYPE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterUsertypeLists: action.payload
            };

        case "GET_MASTER_USERTYPE_USER_WISE_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterUsertypeLists: action.payload
            };

        case "GET_MASTER_USERTYPE_USER_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterUsertypeUserLists: action.payload
            };

        case "GET_MASTER_SARPANCH_BOOKING_LIST_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                masterSarpanchBookingLists: action.payload
            };

        case "GET_REVERSE_GEOCODING_FULFILLED":
            return {
                ...state,
                loadingMaster: false,
                reverseAddressList: action.payload
            };

        case "RESET_MASTER_STATUS":
            return {
                ...state,
                loadingMaster: false,
                errors:{}
            };

        case "GET_MASTER_SETTINGS_REJECTED":
        case "GET_MASTER_STATE_REJECTED":
        case "GET_MASTER_HYDRANT_REJECTED":
        case "GET_MASTER_CITY_STATE_WISE_REJECTED":
        case "GET_MASTER_VEHICLE_REJECTED":
        case "GET_MASTER_DRIVER_REJECTED":
        case "GET_MASTER_VENDOR_REJECTED":
        case "GET_MASTER_DESTINATION_REJECTED":
        case "GET_MASTER_USERTYPE_REJECTED":
        case "GET_MASTER_USERTYPE_USER_WISE_REJECTED":
        case "GET_MASTER_USERTYPE_USER_REJECTED":
        case "GET_REVERSE_GEOCODING_REJECTED":
        case "GET_MASTER_BLOCK_BOOKING_LIST_REJECTED":
        case "GET_MASTER_SARPANCH_BOOKING_LIST_REJECTED":
        case "GET_MASTER_USER_USERTYPE_WISE_REJECTED":
        case "GET_MASTER_VEHICLE_JEN_WISE_REJECTED":
            return {
                ...state,
                loadingMaster: false,
                errorsMaster: action.payload
            };

        default:
            return state;
    }
}

export default masterReducer;