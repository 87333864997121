const initialUserTypePermission = {
    userTypePermissionByUserIdLists: [],
    userTypePermissionStatus: [],
    changedUserTypePermissionStatus : [],
    loadingUserTypePermission: false,
    errorsUserTypePermission: {}
}

const  userTypePermissionReducer = (state = initialUserTypePermission, action) => {
    switch (action.type) {
        case "GET_USERTYPE_PERMISSION_LISTBY_USERTYPE_ID_PENDING":
        case "UPDATE_USERTYPE_PERMISSION__PENDING":
            return { ...state, loadingUserTypePermission: true };

        case "UPDATE_USERTYPE_PERMISSION__FULFILLED":
            return {
                ...state,
                loadingUserTypePermission: false,
                changedUserTypePermissionStatus: action.payload,
            };

        
        case "GET_USERTYPE_PERMISSION_LISTBY_USERTYPE_ID_FULFILLED":
            return {
                ...state,
                loadingUserTypePermission: false,
                userTypePermissionByUserIdLists: action.payload
            };

        case "RESET_DELETE_USERTYPE_PERMISSION__STATUS":
            return {
                ...state,
                loadingUserTypePermission: false,
                userTypePermissionByUserIdLists:[],
                changedUserTypePermissionStatus: [],
                errorsUserTypePermission:{}
            };

        case "UPDATE_USERTYPE_PERMISSION__REJECTED":
        case "GET_USERTYPE_PERMISSION_LISTBY_USERTYPE_ID_REJECTED":
            return {
                ...state,
                loadingUserTypePermission: false,
                errorsUserTypePermission: action.payload
            };

        default:
            return state;
    }
}

export default userTypePermissionReducer;