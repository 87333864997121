import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import Preloader from "../../Components/Preloader";
import LeafletMap from "../../Components/LeafletMap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import swal from "sweetalert2";
import { getTrackDetailsBookingList } from "../../redux/actions/BookingActions";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import "leaflet/dist/leaflet.css";
import LeafletMapHistoryVehicle from "../../Components/LeafletMapHistoryVehicle";
import { getDateTimeMysqlFormatYm, getDisplayDateTimeFormat, getCurrentTimeMysql } from "../../util/generalUtills";

const Dashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [formData, setFormData] = useState({})
  const [videoUrl, setVideoUrl] = useState(null)
  const defaultCenter = { lat: 26.9124, lng: 75.7873 };
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth,loading,errors },
    bookingReducer:{
      trackDetailsBookingList,
      changedBookingStatus,
      loadingBooking,
      errorsBooking,
    },
  } = useSelector((state) => state);

  // const videoUrl = `${trackDetailsBookingList?.generalSettingsData?.app_url}uploads/uploads/${getDateTimeMysqlFormatYm(trackDetailsBookingList.bookingData?.booking_date)}/video/trip_end_${trackDetailsBookingList.bookingData?.id}.mp4`;
  // console.log("Video URL:", videoUrl);

  useEffect(() => {
    if (auth?.token && id) {
      dispatch(getTrackDetailsBookingList(auth.token, id));
    }
  }, [auth?.token, id]);

  useEffect(() => {
    console.log('trackDetailsBookingList',trackDetailsBookingList)
    const appUrl = auth?.generalSettings?.app_url || '';
    const bookingDate = trackDetailsBookingList?.bookingData?.booking_date || '';
    const formattedDate = getDateTimeMysqlFormatYm(bookingDate) || '';

    setVideoUrl(`${appUrl}uploads/uploads/${formattedDate}/video/trip_end_${id}.mp4`);
    //console.log(videoUrl);
  },[trackDetailsBookingList]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status === 401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        window.location.reload();
      }
    }
  },[errors])


  useEffect(() => {
    if(errorsBooking?.status === 409)
    {
      swal.fire({
        show: true,
        title: "Oops!",
        text: errorsBooking?.data?.message,
        icon: "error",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_STATUS' });
    }
    else if(errorsBooking?.status === 400)
    {
      dispatch({ type: 'RESET_DELETE_STATUS' });
    }
  },[errorsBooking])


  useEffect(() => {
    if(changedBookingStatus.success)
    {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedBookingStatus.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_STATUS' });
      navigate(-1);
    }
  },[changedBookingStatus])


  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_BOOKING_STATUS' });
  }, [dispatch]);


  const TimelineEvent = ({ icon, title, description, time, iconBgClass }) => (
    <div className="d-flex align-items-start mb-3">
      <div className={`rounded-circle p-3 ${iconBgClass} text-white`}>
        <strong>{icon}</strong>
      </div>
      <div className="ms-3">
        <h5 className="mb-1">{title}</h5>
        <p className="mb-0">{description}</p>
        <small className="text-muted">{getDisplayDateTimeFormat(time)}</small>
      </div>
    </div>
  );
  

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Booking"), path: "Add-Booking" },
          { label: t("Add Booking"), path: "/Add-Booking", active: true },
        ]}
        title={t("Add Booking")}
      />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h4" style={{ marginBottom: '-14px', marginTop: '6px' }}>Customer Details</Card.Title>
            </Card.Header>
            <hr className="my-2" />
            <Card.Body style={{ marginTop: '-12px' }}>
              <div className="row">
                <div className="col-lg-2">
                  Name: {trackDetailsBookingList?.bookingData?.name}
                </div>

                <div className="col-lg-4">
                  Source: {trackDetailsBookingList?.bookingData?.hydrantName}
                </div>

                <div className="col-lg-4">
                  Destination: {trackDetailsBookingList?.bookingData?.destinationName}
                </div>

                <div className="col-lg-2">
                  Pickup Time: {getCurrentTimeMysql(trackDetailsBookingList?.bookingData?.booking_date)}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
        
      <Row>
        <Col lg={6}>
          

          <Card>
            <Card.Header>
              <Card.Title as="h4" style={{ marginBottom: '-14px', marginTop: '6px' }}>Filled tanker at Hydrant</Card.Title>
            </Card.Header>
            <hr className="my-2" />
            <Card.Body style={{ marginTop: '-12px' }}>
              <div className="mt-4">
                <img
                  style={{ width: '100%', height: '450px' }}
                  src={`${trackDetailsBookingList?.generalSettingsData?.app_url}uploads/uploads/${getDateTimeMysqlFormatYm(trackDetailsBookingList.bookingData?.booking_date)}/hydrant/${trackDetailsBookingList.bookingData?.hydrant_file}`}
                  alt="Filled tanker at hydrant"
                />
              </div>
            </Card.Body>
          </Card>
          {(loadingBooking==false && videoUrl !=null) && (<>
            <Card>
              <Card.Header>
                <Card.Title as="h4" style={{ marginBottom: '-14px', marginTop: '6px' }}>Trip End Video</Card.Title>
              </Card.Header>
              <hr className="my-2" />
              <Card.Body style={{ marginTop: '-12px' }}>
                <div className="mt-4">
                <video
                  style={{ width: '100%', height: '450px' }}
                  controls
                  preload="auto"
                  onError={(e) => console.error('Video error:', e)}
                >
                  <source 
                    src={videoUrl} 
                    type="video/mp4" 
                  />
                  Your browser does not support the video tag.
                </video>
                  {/* <video 
                    style={{ width: '100%', height: '350px' }}
                    controls 
                    preload="auto"
                  >
                    <source 
                      src={`${trackDetailsBookingList?.generalSettingsData?.app_url}uploads/uploads/${getDateTimeMysqlFormatYm(trackDetailsBookingList.bookingData?.booking_date)}/video/trip_end_${trackDetailsBookingList.bookingData?.id}.mp4`}
                      type="video/mp4" 
                    />
                    Your browser does not support the video tag.
                  </video> */}
                </div>
              </Card.Body>
            </Card>
          </>)}
          
        </Col>

        <Col lg={6}>
          <Card>
            <Card.Header>
              <Card.Title as="h4" style={{ marginBottom: '-14px', marginTop: '6px' }}>Booking Status</Card.Title>
            </Card.Header>
            <hr className="my-2" />
            <Card.Body>
              {trackDetailsBookingList?.bookingLog?.map((event, index) => (
                <TimelineEvent key={index} icon={event?.mode} title={event?.status} description={event?.remark} time={event?.createdAt} iconBgClass={event?.mode=="App" ? `bg-warning` : event?.mode=="Web" ? `bg-success` : `bg-info`} />
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {trackDetailsBookingList?.gpsData?.length>0 && (
        <>
          <Row>
            <Col lg={12}>
            <Card>
                <Card.Header>
                  <Card.Title as="h4" style={{ marginBottom: '-14px', marginTop: '6px' }}>Travel upto Hydrant</Card.Title>
                </Card.Header>
                <hr className="my-2" />
                <Card.Body>
                  <div style={{ position: "relative", overflow: "hidden", height: "400px" }}>
                    <LeafletMapHistoryVehicle
                      mapHeight={"400px"}
                      routeData={trackDetailsBookingList?.gpsData}
                      isMarkerShow={false}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      
      {loadingBooking && (<Preloader/>)}
    </>
  );
};

export default Dashboard;