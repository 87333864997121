import React, { useEffect, useMemo } from "react";
import { io } from "socket.io-client";
import { socketUrl } from "../axios"; // Make sure socketUrl is the correct path to your socket server

const Context = React.createContext(null);

const SocketContext = ({ children }) => {
  const socket = useMemo(
    () =>
      io(socketUrl, {
        withCredentials: true,  // Ensures credentials (like cookies) are sent
        transports: ["websocket", "polling"],  // Use WebSocket as primary transport
        autoConnect: false,  // Disable auto-connect for manual connection control
      }),
    []
  );

  useEffect(() => {
    // Connect to the socket server manually
    socket.connect();

    // Listen for connection events
    socket.on("connect", () => {
      console.log("Socket connected with ID:", socket.id);
    });

    // Handle socket disconnection
    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    // Listen for errors
    socket.on("connect_error", (err) => {
      console.error("Socket connection error:", err);
    });

    // Handle reconnection attempts
    socket.on("reconnect", (attemptNumber) => {
      console.log("Socket reconnected, attempt number:", attemptNumber);
    });

    socket.on("reconnect_error", (err) => {
      console.error("Reconnection failed:", err);
    });

    socket.on("reconnect_failed", () => {
      console.error("Reconnection failed after multiple attempts");
    });

    // Cleanup on unmount
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  return <Context.Provider value={{ socket }}>{children}</Context.Provider>;
};

export const useSocketContext = () => {
  return React.useContext(Context);
};

export default SocketContext;
