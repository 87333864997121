import axios from "axios";


  export const getUserPermissionListByUserId =(token,id) =>
  async (dispatch) => {
    try {
      if(id==null)
      {
        return;
      }
      dispatch({ type: "GET_USER_PERMISSION_LISTBY_USER_ID_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/User-Permission/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_USER_PERMISSION_LISTBY_USER_ID_FULFILLED",
        payload: response.data?.data,
        usertypeCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_USER_PERMISSION_LISTBY_USER_ID_REJECTED",
        payload: error.response,
      });
    }
  };

export const updateUserPermissionData = (token, id,permissionType,parentId,menuId,userTypeId) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_USER_PERMISSION__PENDING" });
    const data={
      id:id,
      permissionType:permissionType,
      parentId:parentId,
      menuId:menuId,
      userTypeId:userTypeId,
    }
    const response = await axios.post(
      `/api/admin/v1/Update-User-Permission`, // Endpoint URL with City ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_USER_PERMISSION__FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_USER_PERMISSION__REJECTED", payload: error.response });
  }
};
