const initialState = {
    changedBookingStatus : [],
    trackDetailsBookingList : {},
    slipAllotmentList : [],
    slipAllotmentCount : [],
    currentBookingList : [],
    cancelBookingList : [],
    pendingBookingList : [],
    completeBookingList : [],
    assignBookingList : [],
    currentBookingCount : [],
    cancelBookingCount : [],
    pendingBookingCount : [],
    completeBookingCount : [],
    loadingBooking: false,
    errorsBooking: {}
}

const  bookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_BOOKING_PENDING":
        case "GENERATE_SLIP_PENDING":
        case "GET_CURRENT_JOB_LIST_PENDING":
        case "GET_ASSIGN_BOOKING_PENDING":
        case "ADD_ASSIGN_BOOKING_PENDING":
        case "ADD_CANCEL_BOOKING_PENDING":
        case "GET_SLIP_ALLOTMENT_LIST_PENDING":
        case "GET_SLIP_ALLOTMENT_BY_ID_LIST_PENDING":
        case "GET_TRACK_BOOKING_PENDING":
        case "ADD_SCHEDULE_BOOKING_PENDING":
            return { ...state, loadingBooking: true };

        case "GET_CURRENT_JOB_LIST_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                currentBookingList: action.payload,
                currentBookingCount: action.count
            };

        case "GET_CANCEL_LIST_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                cancelBookingList: action.payload,
                cancelBookingCount: action.count
            };

        case "GET_PENDING_LIST_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                pendingBookingList: action.payload,
                pendingBookingCount: action.count
            };

        case "GET_COMPLETE_LIST_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                completeBookingList: action.payload,
                completeBookingCount: action.count
            };

        case "GET_SLIP_ALLOTMENT_LIST_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                slipAllotmentList: action.payload,
                slipAllotmentCount: action.count
            };

        case "GET_SLIP_ALLOTMENT_BY_ID_LIST_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                slipAllotmentList: action.payload,
            };

        case "GET_ASSIGN_BOOKING_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                assignBookingList: action.payload,
            };

        case "GET_TRACK_BOOKING_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                trackDetailsBookingList: action.payload,
            };

        case "ADD_BOOKING_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                changedBookingStatus: action.payload,
            };

        case "ADD_SCHEDULE_BOOKING_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                changedBookingStatus: action.payload,
            };
        
        case "GENERATE_SLIP_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                changedBookingStatus: action.payload,
            };

        case "ADD_ASSIGN_BOOKING_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                changedBookingStatus: action.payload,
            };

        case "ADD_CANCEL_BOOKING_FULFILLED":
            return {
                ...state,
                loadingBooking: false,
                changedBookingStatus: action.payload,
            };


        case "RESET_DELETE_BOOKING_STATUS":
            return {
                ...state,
                loadingBooking: false,
                changedBookingStatus: [],
                errorsBooking:{}
            };

        case "ADD_BOOKING_REJECTED":
        case "ADD_SCHEDULE_BOOKING_REJECTED":
        case "GENERATE_SLIP_REJECTED":
        case "GET_CURRENT_JOB_LIST_REJECTED":
        case "GET_ASSIGN_BOOKING_REJECTED":
        case "ADD_ASSIGN_BOOKING_REJECTED":
        case "ADD_CANCEL_BOOKING_REJECTED":
        case "GET_SLIP_ALLOTMENT_LIST_REJECTED":
        case "GET_TRACK_BOOKING_REJECTED":
        case "GET_SLIP_ALLOTMENT_BY_ID_LIST_REJECTED":
            return {
                ...state,
                loadingBooking: false,
                errorsBooking: action.payload
            };

        default:
            return state;
    }
}

export default bookingReducer;