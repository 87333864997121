import axios from "axios";

export const getMasterSettings = (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_SETTINGS_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/Settings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_SETTINGS_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_SETTINGS_REJECTED",
        payload: error.response,
      });
    }
  };


export const getAllStateList =
  (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_STATE_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/State`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_STATE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_STATE_REJECTED",
        payload: error.response,
      });
    }
  };

  export const getMasterVehicleListJenWise= (token,jen_id) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_MASTER_VEHICLE_JEN_WISE_PENDING" });
  
        const response = await axios.get(
          `/api/admin/v1/Master/Vehicle?jen_id=${jen_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        //console.log('response.data?.data',response.data?.data);
        dispatch({
          type: "GET_MASTER_VEHICLE_JEN_WISE_FULFILLED",
          payload: response.data?.data,
        });
      } catch (error) {
        console.log(error);
        dispatch({
          type: "GET_MASTER_VEHICLE_JEN_WISE_REJECTED",
          payload: error.response,
        });
      }
    };

export const getMasterHydrantList= (token,jen_id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_HYDRANT_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/Hydrant?jen_id=${jen_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_HYDRANT_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_HYDRANT_REJECTED",
        payload: error.response,
      });
    }
  };
  

export const getAllCityStateWiseList= (token, stateId) =>
  async (dispatch) => {
    console.log('State ID:', stateId);
    try {
      dispatch({ type: "GET_MASTER_CITY_STATE_WISE_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/City`,
        {
          params: {
            state_id: stateId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json"
          },
        }
      );
      console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_CITY_STATE_WISE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.error('Error Response Data:', error.response.data);
        console.error('Error Response Status:', error.response.status);
        console.error('Error Response Headers:', error.response.headers);
      }
      dispatch({
        type: "GET_MASTER_CITY_STATE_WISE_REJECTED",
        payload: error.response,
      });
    }
  };


export const getMasterDestinationList= (token,jen_id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_DESTINATION_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/Destination?jen_id=${jen_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_DESTINATION_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_DESTINATION_REJECTED",
        payload: error.response,
      });
    }
  };

export const getMasterVehicleList= (token,vendorId="") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_VEHICLE_PENDING" });
      let url = `/api/admin/v1/Master/Vehicle`;
      if (vendorId) {
        url += `?vendor_id=${vendorId}`;
      }
      const response = await axios.get(url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_VEHICLE_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_VEHICLE_REJECTED",
        payload: error.response,
      });
    }
  };


export const getMasterDriverList= (token,vendorId="") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_DRIVER_PENDING" });
      let url = `/api/admin/v1/Master/Driver`;
      if (vendorId) {
        url += `?vendor_id=${vendorId}`;
      }
      const response = await axios.get(url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_DRIVER_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_DRIVER_REJECTED",
        payload: error.response,
      });
    }
  };

export const getMasterVendorList= (token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MASTER_VENDOR_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Master/Vendor`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MASTER_VENDOR_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_MASTER_VENDOR_REJECTED",
        payload: error.response,
      });
    }
  };


  export const getMasterUsertypeList= (token) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_MASTER_USERTYPE_PENDING" });
  
        const response = await axios.get(
          `/api/admin/v1/Master/Usertype`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        //console.log('response.data?.data',response.data?.data);
        dispatch({
          type: "GET_MASTER_USERTYPE_FULFILLED",
          payload: response.data?.data,
        });
      } catch (error) {
        console.log(error);
        dispatch({
          type: "GET_MASTER_USERTYPE_REJECTED",
          payload: error.response,
        });
      }
    };


    export const getMasterUsertypeUserWiseList= (token) =>
      async (dispatch) => {
        try {
          dispatch({ type: "GET_MASTER_USERTYPE_USER_WISE_PENDING" });
    
          const response = await axios.get(
            `/api/admin/v1/Master/Usertype-User-Wise`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          //console.log('response.data?.data',response.data?.data);
          dispatch({
            type: "GET_MASTER_USERTYPE_USER_WISE_FULFILLED",
            payload: response.data?.data,
          });
        } catch (error) {
          console.log(error);
          dispatch({
            type: "GET_MASTER_USERTYPE_USER_WISE_REJECTED",
            payload: error.response,
          });
        }
      };


  export const getAllUsertypeUserList= (token, user_type,city) =>
    async (dispatch) => {
      console.log('Type:', token, user_type,city);
      try {
        dispatch({ type: "GET_MASTER_USERTYPE_USER_PENDING" });
  
        const response = await axios.get(
          `/api/admin/v1/Master/User-Usertype-City-Wise`,
          {
            params: {
              user_type: user_type,
              city:city,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json"
            },
          }
        );
        console.log('response.data?.data',response.data?.data);
        dispatch({
          type: "GET_MASTER_USERTYPE_USER_FULFILLED",
          payload: response.data?.data,
        });
      } catch (error) {
        console.log(error);
        if (error.response) {
          console.error('Error Response Data:', error.response.data);
          console.error('Error Response Status:', error.response.status);
          console.error('Error Response Headers:', error.response.headers);
        }
        dispatch({
          type: "GET_MASTER_USERTYPE_USER_REJECTED",
          payload: error.response,
        });
      }
    };
  

export const getAddressByLatLng= (lat,lng) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_REVERSE_GEOCODING_PENDING" });

      const response = await axios.get(
        `https://mapapi.asginnovations.in/v1/reverse?point.lat=${lat}&point.lon=${lng}&size=1&layers=coarse,venue,address`,
        {
          headers: {
            'X-API-Key': 'asginnovationsdemoapikey',
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_REVERSE_GEOCODING_FULFILLED",
        payload: response.data?.features[0].properties,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_REVERSE_GEOCODING_REJECTED",
        payload: error.response,
      });
    }
  };


  export const getMasterBlockBookingList= (token,jen_id) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_MASTER_BLOCK_BOOKING_LIST_PENDING" });
  
        const response = await axios.get(
          `/api/admin/v1/Master/Block-Booking-List?jen_id=${jen_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        //console.log('response.data?.data',response.data?.data);
        dispatch({
          type: "GET_MASTER_BLOCK_BOOKING_LIST_FULFILLED",
          payload: response.data?.data,
        });
      } catch (error) {
        console.log(error);
        dispatch({
          type: "GET_MASTER_BLOCK_BOOKING_LIST_REJECTED",
          payload: error.response,
        });
      }
    };



  export const getMasterSarpanchBookingList= (token,jen_id) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_MASTER_SARPANCH_BOOKING_LIST_PENDING" });
  
        const response = await axios.get(
          `/api/admin/v1/Master/Sarpanch-Booking-List?jen_id=${jen_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        //console.log('response.data?.data',response.data?.data);
        dispatch({
          type: "GET_MASTER_SARPANCH_BOOKING_LIST_FULFILLED",
          payload: response.data?.data,
        });
      } catch (error) {
        console.log(error);
        dispatch({
          type: "GET_MASTER_SARPANCH_BOOKING_LIST_REJECTED",
          payload: error.response,
        });
      }
    };


  export const getMasterUserUserTypeWiseList= (token, user_type_id) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_MASTER_USER_USERTYPE_WISE_PENDING" });
  
        const response = await axios.get(
          `/api/admin/v1/Master/User-Usertype-Wise`,
          {
            params: {
              user_type_id: user_type_id,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json"
            },
          }
        );
        // console.log('response.data?.data',response.data?.data);
        dispatch({
          type: "GET_MASTER_USER_USERTYPE_WISE_FULFILLED",
          payload: response.data?.data,
        });
      } catch (error) {
        console.log(error);
        if (error.response) {
          console.error('Error Response Data:', error.response.data);
          console.error('Error Response Status:', error.response.status);
          console.error('Error Response Headers:', error.response.headers);
        }
        dispatch({
          type: "GET_MASTER_USER_USERTYPE_WISE_REJECTED",
          payload: error.response,
        });
      }
    };