import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getHydrantListById, saveHydrantData, updateHydrantData } from "../../redux/actions/HydrantActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import LeafletSingleMap from "../../Components/LeafletSingleMap";
import { getAddressByLatLng, getAllUsertypeUserList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    lat:0.00,
    lng:0.00
  });
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    hydrantReducer: {
      loadingHydrant,
      errorsHydrant,
      hydrantLists,
      changedHydrantStatus,
    },
    masterReducer:{
      reverseAddressList,
      masterUsertypeUserLists,
    }
  } = useSelector((state) => state);

  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(id){
      dispatch(updateHydrantData(auth?.token, formData,id));
    }
    else{
      dispatch(saveHydrantData(auth?.token, formData));
    }
  };

  useEffect(() => {
    //setFormData({});
    //dispatch({ type: 'RESET_DELETE_HYDRANTS_STATUS' });
  }, [dispatch]);


  useEffect(() => {
    dispatch(getAllUsertypeUserList(auth.token, 13,''));
    if(id)
    {
      dispatch(getHydrantListById(auth?.token, id));
    }
  }, [auth, id]);

  useEffect(()=>{
    if(id)
    {
      if(hydrantLists && Object.keys(hydrantLists).length>0)
      {
        setFormData({
          name: hydrantLists?.name,
          lat: hydrantLists?.lat,
          lng: hydrantLists?.lng,
          address: hydrantLists?.address,
          jen_id: hydrantLists?.jen_id,
          hydantId: hydrantLists?.hydantId,
        });
      }
    }
    
  },[hydrantLists])

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsHydrant && Object.keys(errorsHydrant).length > 0)
    {
      if (errorsHydrant?.status === 409)
      {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsHydrant?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_HYDRANTS_STATUS' });
      }
      else if (errorsHydrant?.status === 400)
      {
        //
      }
      else
      {
        dispatch({ type: 'RESET_DELETE_HYDRANTS_STATUS' });
      }
    }
  }, [errorsHydrant, dispatch]);

  useEffect(() => {
    if (changedHydrantStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedHydrantStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_HYDRANTS_STATUS' });
      navigate(-1);
    }
  }, [changedHydrantStatus, dispatch, navigate]);

  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_HYDRANT_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectHandler = (selectedData,name) => {
    //console.log(selectedData,name);
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData
    }));
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position);
          setFormData({ ...formData, lat: position.coords.latitude,lng: position.coords.longitude });
        },
        (error) => {
          //setError(error.message);
        }
      );
    } else {
      //setError('Geolocation is not supported by this browser.');
    }
  }, []);

  useEffect(()=>{
    setFormData({ ...formData, address: reverseAddressList?.label });
  },[reverseAddressList])

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Hydrant"), path: "Hydrant" },
          { label: t("Add Hydrant"), path: "/Add-Hydrant", active: true },
        ]}
        title={t("Add Hydrant")}
      />
      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <div style={{ position: "relative", overflow: "hidden", height: "500px" }}>
                <LeafletSingleMap
                  mapHeight={"600px"}
                  center={[formData.lat || 0.00, formData.lng || 0.00]}
                  zoom={15}
                  setFormData={setFormData}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <Card.Body>
              {errorsHydrant?.data?.message && (
                <div className="alert alert-danger">
                  {errorsHydrant?.data?.message}
                </div>
              )}

              <Row>
                <Col lg={12}>
                  <FormInput
                    label={t("Hydant Id")}
                    type="text"
                    errors={errorsHydrant?.data}
                    value={formData.hydantId}
                    name="hydantId"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={12}>
                  <FormInput
                    label={t("Name")}
                    type="text"
                    errors={errorsHydrant?.data}
                    value={formData.name}
                    name="name"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={6}>
                  <FormInput
                    label={t("Latitude")}
                    type="text"
                    errors={errorsHydrant?.data}
                    value={formData?.lat}
                    name="lat"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={6}>
                  <FormInput
                    label={t("Longitude")}
                    type="text"
                    errors={errorsHydrant?.data}
                    value={formData?.lng}
                    name="lng"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={12}>
                  <FormInput
                    label={t("Address")}
                    type="text"
                    errors={errorsHydrant?.data}
                    value={formData.address}
                    name="address"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("J.E.N.")}</Form.Label>
                    <Select
                      options={masterUsertypeUserLists}
                      values={masterUsertypeUserLists?.filter(data => data?.id === formData?.jen_id)}
                      name="jen_id"
                      labelField={"name"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler(selectedData[0]?.id,"jen_id")}
                      styles={customSelectStyles}
                    />
                    {errorsHydrant?.data?.jen_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsHydrant?.data?.jen_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              
              <div className="d-flex justify-content-center">
                {loading ? 
                  <Button color="primary" disabled>
                    <Spinner
                      className="spinner-border-sm me-1"
                      tag="span"
                      color="white"
                    />
                    Please wait ...
                  </Button>
                :
                  <Button type="button" onClick={handleSubmit} disabled={loading}>
                    Submit
                  </Button>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingHydrant && (<Preloader/>)}
    </>
  );
};

export default Dashboard;