const MENU_ITEMS = [
    {
      key: "dashboards",
      label: "Dashboards",
      isTitle: false,
      icon: "airplay",
      url: "/",
    },
    
    {
      key: "master",
      label: "Master",
      isTitle: false,
      icon: "airplay",
      children: [
        {
          key: "master-users",
          label: "Users",
          url: "/Users",
          parentKey: "master",
        },
        {
          key: "master-state",
          label: "State",
          url: "/State",
          parentKey: "master",
        },
        {
          key: "master-city",
          label: "City",
          url: "/City",
          parentKey: "master",
        },
        {
          key: "master-vendor",
          label: "Vendor",
          url: "/Vendor",
          parentKey: "master",
        },
        {
          key: "master-vehicle",
          label: "Vehicle",
          url: "/Vehicle",
          parentKey: "master",
        },
        {
          key: "master-driver",
          label: "Driver",
          url: "/Driver",
          parentKey: "master",
        },
        {
          key: "master-hydrant",
          label: "Hydrant",
          url: "/Hydrant",
          parentKey: "master",
        },
        {
          key: "master-destination",
          label: "Destination",
          url: "/Destination",
          parentKey: "master",
        },
        {
          key: "master-fare",
          label: "Fare Master",
          url: "/Fare-Master",
          parentKey: "master",
        },
      ],
    },
    {
      key: "booking-add",
      label: "Generate Slip",
      isTitle: false,
      icon: "airplay",
      url: "/Generate-Slip",
    },
    {
      key: "schedule-booking-add",
      label: "Schedule Booking",
      isTitle: false,
      icon: "airplay",
      url: "/Schedule-Booking",
    },
    
    // {
    //   key: "booking-add",
    //   label: "Add New Booking",
    //   isTitle: false,
    //   icon: "airplay",
    //   url: "/Add-Booking",
    // },
    {
      key: "booking",
      label: "Booking",
      isTitle: false,
      icon: "airplay",
      children: [
        {
          key: "booking-current",
          label: "Current",
          url: "/Current-Booking",
          parentKey: "booking",
        },
        {
          key: "booking-pending",
          label: "Verification Pending",
          url: "/Pending-Booking",
          parentKey: "booking",
        },
        {
          key: "booking-complete",
          label: "Partial Complete",
          url: "/Complete-Booking",
          parentKey: "booking",
        },
        {
          key: "booking-cancel",
          label: "Cancel",
          url: "/ ",
          parentKey: "booking",
        },
      ],
    },
    {
      key: "map",
      label: "Map",
      isTitle: false,
      icon: "map",
      children: [
        {
          key: "live-tracking",
          label: "Live Tracking",
          url: "/Live-Tracking",
          parentKey: "map",
        },
        {
          key: "history-tracking",
          label: "History Tracking",
          url: "/History-Tracking",
          parentKey: "map",
        },
      ],
    },
    {
      key: "verified-report",
      label: "Verified Report",
      isTitle: false,
      icon: "airplay",
      children: [
        {
          key: "trip-report",
          label: "Trip Report",
          url: "/Trip-Report",
          parentKey: "verified-report",
        },
        {
          key: "revenue-report",
          label: "Revenue Report",
          url: "/Revenue-Report",
          parentKey: "verified-report",
        },
        {
          key: "monthly-revenue-report",
          label: "Monthly Revenue Report",
          url: "/Monthly-Revenue-Report",
          parentKey: "verified-report",
        },
      ],
    },
    {
      key: "provisional-report",
      label: "Provisional Report",
      isTitle: false,
      icon: "airplay",
      children: [
        {
          key: "provisional-trip-report",
          label: "Trip Report",
          url: "/Provisional-Trip-Report",
          parentKey: "provisional-report",
        },
        {
          key: "provisional-revenue-report",
          label: "Revenue Report",
          url: "/Provisional-Revenue-Report",
          parentKey: "provisional-report",
        },
        {
          key: "provisional-monthly-revenue-report",
          label: "Monthly Revenue Report",
          url: "/Provisional-Monthly-Revenue-Report",
          parentKey: "provisional-report",
        },
      ],
    },
  ];
  
  export { MENU_ITEMS };
  