import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getCancelBookingList } from "../../redux/actions/BookingActions";
import RoundedPagination from "../../Components/Paginate";
import FormInput from "../../Components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../util/generalUtills";
import Preloader from "../../Components/Preloader";



const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    bookingReducer: {
      cancelBookingList,
      loadingBooking,
      errorsBooking,
      cancelBookingCount,
      changedBookingStatus,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  
  // useEffect(() => {
  //   dispatch(getCancelBookingList(auth?.token, paginate, page, search, sort));
  //   console.log('cancelBookingList',cancelBookingList);
  // }, [auth, paginate, page, search, sort]);

  const fetchCancelBookingList = useCallback(() => {
    if (auth?.token) {
      dispatch(getCancelBookingList(auth.token, paginate, page, search, sort));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchCancelBookingList, 500, [auth, paginate, page, search, sort]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])



  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? cancelBookingCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1); // Reset to the first page whenever the paginate value changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };


  useEffect(() => {
    if(changedBookingStatus?.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changedBookingStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_BOOKING_STATUS' });
      dispatch(getCancelBookingList(auth?.token, paginate, page, search, sort));
    }
    
  },[changedBookingStatus])

  useEffect(() => {
    //console.log('errorsBooking',errorsBooking);
    if(errorsBooking?.status==401){
      dispatch(logout())
      toast.error(errors?.data?.message);
      navigate('/login');
      //window.location.reload();
    }
    else if(errorsBooking?.status==400){
      //dispatch({ type: 'RESET_DELETE_STATUS' });
    }
  },[errorsBooking])


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Cancel Booking List"), path: "/Cancel Booking List", active: true },
        ]}
        title={t("Cancel Booking List")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    <div className="d-flex align-items-center">
                    <input
                      type="search"
                      placeholder="Search..."
                      className="form-control ms-1"
                      autoComplete="off"
                      value={search}
                      onChange={handleSearchChange}
                    />
                    </div>
                  </Col>
                </Row>
              </div>

              <div style={{overflowY:'auto',maxHeight:'500px',border: "1px solid #dee2e6"}}>
                <Table className="mb-0 table-bordered"
                  size="sm"
                  striped
                  style={{ width: "100%", tableLayout: "fixed" }}>
                  <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
                    <tr>
                      <th>SR No.</th>
                      <th>Job Id</th>
                      <th>Number/Name</th>
                      <th>Booking Time</th>
                      <th>Vehicle</th>
                      <th>Hydrant</th>
                      <th>Drop Location</th>
                      <th>Booked By / Date Time</th>
                      <th>Assigned By / Date</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                  {cancelBookingList.length === 0 ? (
                    <tr>
                      <td colSpan="10">No data found</td>
                    </tr>
                  ) : (<>
                    {cancelBookingList?.map?.((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>{item?.bookingId}</td>
                          <td>{item?.name}<br/>{item?.mobile}<br/>{item?.booking_type}</td>
                          <td>{getDisplayDateTimeFormat(item?.booking_date)}</td>
                          <td>
                            {item?.vehicle_number ? (
                              <>
                              {item?.vehicle_number} <br/>{item?.driver_name}<br/>{item?.driver_mobile}
                              </>
                            ) : (
                              <>
                                Vehicle Not Assign
                              </>
                            )}
                          </td>
                          <td>{item?.hydrantName}</td>
                          <td>{item?.destinationName}</td>
                          <td>{item?.createdByBookingDetailsAdminUsers?.name}<br/>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                          <td>{item?.createdByBookingDetailsAdminUsers?.name}<br/>{getDisplayDateTimeFormat(item?.assign_at)}</td>
                          <td>{item?.cancel_reason}</td>
                        </tr>
                      );
                    })}</>
                  )}
                  </tbody>
                </Table>
              </div>
              <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(cancelBookingCount / paginate)}</strong> </span>
                <RoundedPagination
                      currentPage={page}
                      totalPages={Math.ceil(cancelBookingCount / paginate)}
                      onPageChange={(pageNumber) => setPage(pageNumber)}
                    />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingBooking && (<Preloader/>)}

    </>
  );
};

export default Dashboard;
