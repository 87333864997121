import axios from "axios";
import { getDateTimeMysqlGivenFormatYmdhi } from "../../util/generalUtills";

export const getCurrentBookingList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_CURRENT_JOB_LIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Current-Booking`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_CURRENT_JOB_LIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_CURRENT_JOB_LIST_REJECTED",
        payload: error.response,
      });
    }
  };

  export const getSlipAllotmentList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_SLIP_ALLOTMENT_LIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Slip-Allotment`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_SLIP_ALLOTMENT_LIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_SLIP_ALLOTMENT_LIST_REJECTED",
        payload: error.response,
      });
    }
  };

  export const getSlipAllotmentListById =
  (token, id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_SLIP_ALLOTMENT_BY_ID_LIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Slip-Allotment/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_SLIP_ALLOTMENT_BY_ID_LIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_SLIP_ALLOTMENT_BY_ID_LIST_REJECTED",
        payload: error.response,
      });
    }
  };


export const getassignBookingList =
  (token, bookingid , limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_ASSIGN_BOOKING_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Assign-Booking/${bookingid}`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_ASSIGN_BOOKING_FULFILLED",
        payload: response.data,
        count: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_ASSIGN_BOOKING_REJECTED",
        payload: error.response,
      });
    }
  };

  export const saveBookingData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_BOOKING_PENDING" });
      data.booking_date=getDateTimeMysqlGivenFormatYmdhi(data.booking_date)
      //console.log('datadata',data);
      const response = await axios.post(
        `/api/admin/v1/Save-Booking`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_BOOKING_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_BOOKING_REJECTED", payload: error.response });
    }
  };

  export const generateSlipData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "GENERATE_SLIP_PENDING" });
      data.booking_date=getDateTimeMysqlGivenFormatYmdhi(data.booking_date)
      //console.log('datadata',data);
      const response = await axios.post(
        `/api/admin/v1/Generate-Slip`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "GENERATE_SLIP_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "GENERATE_SLIP_REJECTED", payload: error.response });
    }
  };

  export const assignBookingData = (token, bookingId,vehicleId) => async (dispatch) => {
    try {
      const param={
        "booking_id":bookingId,
        "vehicle_id":vehicleId
      }
        
      console.log('token, data', token, param);
      dispatch({ type: "ADD_ASSIGN_BOOKING_PENDING" });
      const response = await axios.post(
        `/api/admin/v1/Assign-Vehicle`,
        param, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_ASSIGN_BOOKING_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_ASSIGN_BOOKING_REJECTED", payload: error.response });
    }
  };

  export const cancelBookingData = (token, bookingId,cancelId) => async (dispatch) => {
    try {
      const param={
        "booking_id":bookingId,
        "reason":cancelId
      }
        
      console.log('token, data', token, param);
      dispatch({ type: "ADD_CANCEL_BOOKING_PENDING" });
      const response = await axios.post(
        `/api/admin/v1/Cancel-Booking`,
        param, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_CANCEL_BOOKING_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_CANCEL_BOOKING_REJECTED", payload: error.response });
    }
  };


  export const getCancelBookingList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_CANCEL_LIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Cancel-Booking`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_CANCEL_LIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_CANCEL_LIST_REJECTED",
        payload: error.response,
      });
    }
  };

  export const getPendingBookingList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_PENDING_LIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Pending-Verification`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_PENDING_LIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_PENDING_LIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const getCompleteBookingList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_COMPLETE_LIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Complete-Booking`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_COMPLETE_LIST_FULFILLED",
        payload: response.data?.data,
        count: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_COMPLETE_LIST_REJECTED",
        payload: error.response,
      });
    }
  };
  
  export const saveScheduleBookingData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_SCHEDULE_BOOKING_PENDING" });
      data.booking_date=getDateTimeMysqlGivenFormatYmdhi(data.booking_date)
      //console.log('datadata',data);
      const response = await axios.post(
        `/api/admin/v1/Save-Schedule-Booking`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_SCHEDULE_BOOKING_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_SCHEDULE_BOOKING_REJECTED", payload: error.response });
    }
  };

  export const getTrackDetailsBookingList =
  (token, bookingid , limit = 10, page = 1, search = "", sort = "desc") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_TRACK_BOOKING_PENDING" });

        const response = await axios.get(
          `/api/admin/v1/Booking-Details/${bookingid}`,
          {
            params: {
              limit: limit,
              page: page,
              search: search,
              sort: sort,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_TRACK_BOOKING_FULFILLED",
          payload: response.data,
          count: response.data?.count,
        });
      } catch (error) {
        console.log(error);
        dispatch({
          type: "GET_TRACK_BOOKING_REJECTED",
          payload: error.response,
        });
      }
    };