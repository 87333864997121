import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Badge } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { deleteVehicleData, getVehicleList } from "../../redux/actions/VehicleActions";
import RoundedPagination from "../../Components/Paginate";
import FormInput from "../../Components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import {getDisplayDateTimeFormat} from '../../util/generalUtills'
import { getslipAllotmentList, getSlipAllotmentList } from "../../redux/actions/BookingActions";
import Preloader from "../../Components/Preloader";



const GenerateSlipList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    bookingReducer: {
      slipAllotmentList,
      slipAllotmentCount,
      loadingBooking,
      errorsBooking,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(100);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  
  // useEffect(() => {
  //   dispatch(getVehicleList(auth?.token, paginate, page, search, sort));
  //   console.log('slipAllotmentList',slipAllotmentList);
  // }, [auth, paginate, page, search, sort]);
  

  const fetchDataList = useCallback(() => {
    if (auth?.token) {
      dispatch(getSlipAllotmentList(auth.token, paginate, page, search, sort));
    }
  }, [auth?.token, paginate, page, search, sort, dispatch]);

  useDebounce(fetchDataList, 500, [auth, paginate, page, search, sort]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
    
  },[errors])


  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? slipAllotmentCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1); // Reset to the first page whenever the paginate value changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleDeleteRequest = (id) => {
    swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, delete it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteVehicleData(auth?.token,id))
      }
    });
  };


  // useEffect(() => {
  //   //console.log(changedVehicleStatus);
  //   if(changedVehicleStatus.status)
  //   {
  //     swal.fire({
  //       show: true,
  //       title: "Deleted!",
  //       text: changedVehicleStatus.message,
  //       icon: "success",
  //       showConfirmButton: false,
  //       timer: 1500
  //     });
  //     dispatch({ type: 'RESET_DELETE_STATUS' });
  //     dispatch(getVehicleList(auth?.token, paginate, page, search, sort));
  //   }
    
  // },[changedVehicleStatus])

  useEffect(() => {
    //console.log('errorsBooking',errorsBooking);
    if(errorsBooking?.status==401){
      dispatch(logout())
      toast.error(errors?.data?.message);
      navigate('/login');
      //window.location.reload();
    }
    else if(errorsBooking?.status==400){
      //dispatch({ type: 'RESET_DELETE_STATUS' });
    }
  },[errorsBooking])

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Generate Slip"), path: "/Generate Slip", active: true },
        ]}
        title={t("Generate Slip")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
            <div className="mb-2">
              <Row>
                <Col lg={3}>
                  <div className="d-flex align-items-center">
                  <input
                    type="search"
                    placeholder="Search..."
                    className="form-control ms-1"
                    autoComplete="off"
                    value={search}
                    onChange={handleSearchChange}
                  />
                  </div>
                </Col>
                <Col lg={9}>
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="button-list">
                      <Button variant="success" onClick={fetchDataList}>
                        <i className="mdi mdi-clipboard-plus-outline me-1"></i>Refresh
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div style={{overflowY:'auto',maxHeight:'500px',border: "1px solid #dee2e6"}}>
              <Table className="mb-0 table-bordered"
                size="sm"
                striped
                style={{ width: "100%", tableLayout: "fixed" }}>
                  <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
                  <tr>
                    <th>SR No.</th>
                    <th>J.E.N.</th>
                    <th>Vehicle</th>
                    <th>Driver</th>
                    <th>Hydrant</th>
                    <th>Available Date Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {slipAllotmentList.length === 0 ? (
                  <tr>
                    <td colSpan="7">No data found</td>
                  </tr>
                ) : (<>
                  {slipAllotmentList?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                        <td>{item?.jen_idSlipGenerationDetailsAdminUsers?.name} <br/>{item?.jen_idSlipGenerationDetailsAdminUsers?.city?.city_name}</td>
                        <td>
                          {auth?.user_type_id==13 && (
                            <>
                            {item?.vehicle_number} <br/>Tanker
                            </>
                          )}
                          {auth?.user_type_id!=13 && (
                            <>
                            <span style={{color:'red'}}>Contact J.E.N. For booking assign</span>
                            </>
                          )}
                        </td>
                        <td>{item?.driver_name}<br/>{item?.driver_mobile}</td>
                        <td>{item?.hydrantName}<br/>{item?.hydrantIdSlipGenerationDetailsHydrant?.address}</td>
                        <td>{item?.createdByBookingDetailsAdminUsers?.name}<br/>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                        <td>
                          <Link to={`/Generate-Slip/${item?.id}`} className="btn btn-xs btn-light">
                            <Badge
                              bg=""
                              className={"badge-outline-secondary"}
                              key={index}
                            >Generate Slip</Badge>
                          </Link>
                          
                        </td>
                      </tr>
                    );
                  })}</>
                )}
                </tbody>
              </Table>
            </div>
            <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
              
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option selected value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(slipAllotmentCount / paginate)}</strong> </span>
                <RoundedPagination
                      currentPage={page}
                      totalPages={Math.ceil(slipAllotmentCount / paginate)}
                      onPageChange={(pageNumber) => setPage(pageNumber)}
                    />
              
              
            </div>
            
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingBooking && (<Preloader/>)}

    </>
  );
};

export default GenerateSlipList;
